import React from "react";

interface PawnProps {
  size?: number;
  className?: string;
  color?: string;
}

const Pawn: React.FC<PawnProps> = ({
  size = 24,
  className,
  color = "#ff6200",
}) => {
  const aspectRatio = 9 / 14;
  const width = size * aspectRatio;

  return (
    <svg
      width={width}
      height={size}
      fill="none"
      viewBox="0 0 9 14"
      className={className}
    >
      <path
        fill={color}
        d="M8.833 12.912a8.564 8.564 0 00-.66-2.08c-.27-.546-.491-1.108-.491-1.25 0-.14-.17-.256-.378-.256-.83 0-1.385-.929-1.385-2.315 0-1.13.079-1.358.518-1.494.717-.222.562-1.195-.208-1.301l-.57-.08.508-.628C7.29 2.116 6.378 0 4.654 0c-1.118 0-1.746.403-2.17 1.394-.338.788-.329.946.106 1.803.46.908.461.942.014 1.057-.639.162-.608 1.154.04 1.32 1.141.29.305 3.752-.905 3.752-.34 0-.933.99-.973 1.623-.01.15-.143.426-.295.613s-.29.506-.308.709c-.017.202-.06.644-.097.981L0 13.866l4.5.067L9 14l-.167-1.088zm-8.455.334c0-.481.128-.495 4.09-.43 3.662.061 4.098.113 4.173.498.078.395-.25.429-4.089.429-4.023 0-4.174-.018-4.174-.497zM.63 12.13c0-.518.613-1.086 1.178-1.09.363-.003.386-.05.107-.22a.517.517 0 01-.193-.619c.135-.343.55-.391 2.867-.332 2.32.06 2.72.13 2.791.49.072.363-.198.43-2.012.502l-2.094.083 2.165.05c1.78.04 2.243.134 2.606.525.798.859.292.997-3.652.997-3.253 0-3.763-.052-3.763-.386zm2.248-3.206c.745-.922.758-3.42.019-3.648-.791-.244-.597-.795.314-.893 2.06-.222 4.466.458 2.97.84-.45.114-.514.326-.514 1.694 0 1.018.137 1.754.392 2.109l.392.545H2.356l.522-.647zm.954-2.42c-.003-.675-.051-.922-.109-.55-.058.374-.056.926.004 1.228.06.302.107-.004.105-.679zm-.684-3.068c-.75-.731-.671-2.045.16-2.682.876-.671 1.634-.642 2.448.093.82.741.868 1.735.119 2.512-.697.723-2.025.76-2.727.077zm.362-.318c-.095-.242-.236-.378-.313-.302-.078.075 0 .273.172.44.249.24.278.212.14-.138zm-.196-1.465c.117-.19.439-.472.716-.624.39-.215.412-.28.102-.285-.447-.009-1.398.897-1.173 1.117.079.076.238-.017.355-.208z"
      ></path>
    </svg>
  );
};

export default Pawn;
