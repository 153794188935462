import React from "react";

function QuestionMark({ size, isLargeBreakpoint }) {
  // Fixed aspect ratio based on the original dimensions
  const aspectRatio = 491.397 / 778.058; // originalWidth / originalHeight

  // Calculate width and height based on the provided size and aspect ratio
  let width, height;
  if (size) {
    // Maintain aspect ratio
    width = size * aspectRatio;
    height = size;
  } else {
    // Default size based on original dimensions
    width = 491.397;
    height = 778.058;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 110.112 174.346"
      style={{
        position: "absolute",
        zIndex: "-1",
        width: `45vw`,
        maxWidth: "525px",
        left: isLargeBreakpoint ? 'auto' : '50%',
        transform: isLargeBreakpoint ? 'none' : 'translateX(-50%)',
        top: isLargeBreakpoint ? "457px" : "17rem"
      }}
    >
      <g transform="translate(308.704 -179.574)">
        <path
          fill="#717171"
          stroke="none"
          strokeWidth="0.224"
          d="M-294.516 223.95c12.867-6.77 27.886-13.263 42.799-11.838 6.564.627 14.468 4.13 14.993 11.614.152 2.172-.27 4.442-.956 6.499-1.603 4.814-5.312 8.335-9.107 11.52-6.687 5.61-14.374 9.827-20.371 16.265-9.568 10.272-9.656 22.183-9.656 35.405h33.388c0-4.557-.522-9.628 1.414-13.893 1.909-4.204 5.755-7.025 9.342-9.72 6.139-4.61 12.661-8.68 18.374-13.847 12.24-11.07 18.036-26.65 14.833-42.985-.646-3.296-1.85-6.418-3.35-9.41-11.424-22.791-40.577-25.903-63.021-23.145-4.466.55-8.857 1.34-13.22 2.445-7.87 1.993-15.597 4.8-22.857 8.43a159.153 159.153 0 00-4.93 2.582c-.585.319-1.65.72-1.833 1.443-.177.7.467 1.604.771 2.194.889 1.723 1.819 3.419 2.686 5.154 3.531 7.061 6.722 14.47 10.7 21.287m50.643 69.017l-32.667-.022c-.089-16.615 1.674-26.883 10.727-35.828 8.36-8.755 22.728-13.582 27.92-25.1.838-1.86 1.217-3.819 1.477-5.826.829-6.377-3.508-11.122-9.25-13.208-8.176-2.972-18.322-1.188-26.441 1.14-5.305 1.52-10.45 3.562-15.462 5.863-2.292 1.053-4.518 2.592-6.946 3.292l-13.893-28.01c15.059-9.216 32.502-13.837 49.97-15.033 8.074-.553 16.511-.313 24.424 1.563 6.252 1.481 12.383 3.755 17.702 7.426 14.136 9.755 20.02 26.4 16.212 43.017-3.035 13.244-12.468 22.661-23.158 30.38-6.131 4.427-14.75 8.732-18.529 15.556-2.475 4.471-2.086 9.843-2.086 14.79m-16.806 18.693c-5.637.74-11.476 1.928-15.621 6.218-7.004 7.248-6.481 23.382.832 30.192 5.531 5.15 13.794 6.536 21.063 5.557 5.424-.73 11.082-3.535 14.358-8.002 4.928-6.72 4.927-18.907.264-25.77-4.192-6.169-13.699-9.14-20.896-8.195m-.224.417c6.999-.826 15.45 1.652 20.1 7.107 5.88 6.9 5.336 21.27-.85 27.782-3.736 3.933-9.06 5.815-14.32 6.438-6.709.794-14.832-1.122-19.7-6.006-6.634-6.658-6.952-21.913-.653-28.886 3.926-4.345 9.86-5.778 15.423-6.435z"
        ></path>
      </g>
    </svg>
  );
}

export default QuestionMark;
