import React from "react";

interface QueenProps {
  size?: number;
  className?: string;
}

const Queen: React.FC<QueenProps> = ({ size = 24, className }) => {
  // Calculate width based on the aspect ratio (width:height = 170:402)
  const width = (size / 402) * 170;
  const widthPx = `${width}px`;

  return (
    <div style={{ width: widthPx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={size}
        fill="none"
        viewBox="0 0 170 402"
        className={
          className ||
          "drop-shadow-[0_0_5px_rgba(0,102,255,0.75)] drop-shadow-[0_0_10px_rgba(0,102,255,0.75)]"
        }
      >
        <g id="layer1" transform="translate(-361.195 -53.774)">
          <g
            id="g51494"
            fill="#448efd"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="4"
            strokeWidth="0.915"
            paintOrder="markers stroke fill"
          >
            <path
              id="path51014"
              d="M240.058 229.985c-14.916.228-30.057-.19-44.832.808-2.703 62.915-1.959 125.998-6.865 188.812-1.403 16.747-4.3 34.108-14.523 47.962-1.698 2.591-5.382 7.674-.484 9.094 11.783 4.264 24.652 1.898 36.911 2.67 30.067-.21 60.188.636 90.217-1.047 3.701-.196 9.157-1.74 9.758-5.48-6.246-11.827-10.642-24.558-12.033-37.906-6.476-44.13-6.742-88.852-8.924-133.325-1.108-23.581-1.44-47.22-3.283-70.754-15.284-1.18-30.628-.753-45.942-.834"
              transform="matrix(.26458 0 0 .26458 339.68 80.326)"
            ></path>
            <path
              id="path51053"
              d="M230.83 141.368c-12.846.501-27.39-.878-38.328 7.114-3.243 3.878-5.363 9.626-11.286 10.042-5.506 1.153-14.161 2.237-15.322 8.92 2.446 5.559 10.05 5.751 15.193 7.372 23.012 4.717 46.797 5.074 69.907 8.065-1.376 1.82-5.505.485-7.726 1.178-31.707 2.13-63.682 1.183-95.18 5.807-6.715.923-16.73 3.872-15.982 12.402 2.33 8.481 13.047 8.929 20.217 10.192 34.282 3.214 68.79 1.783 103.173 1.977 26.318-.66 52.826.64 78.978-2.887 6.004-.938 15.841-3.362 14.63-11.369-.46-8.313-9.354-10.576-16.103-11.535-11.158-2.502-22.979-1.697-33.897-5.073-.917-2.062 3.778-2.847 4.898-4.116 4.557-2.69 12.915-5.706 11.408-12.383-5.53-10.614-17.952-14.976-28.532-18.978-18.039-6.53-37.093-6.977-56.047-6.728"
              transform="matrix(.26458 0 0 .26458 339.68 80.326)"
            ></path>
            <path
              id="path51092"
              d="M239.56-48.567c-16.911.331-32.109 9.5-43.765 21.19-9.095 8.41-18.72 18.719-31.772 19.876-4.28.451-9.382-4.243-13.136-1.115-2.815 6.872 2.299 13.993 4.542 20.359 14.034 29.718 32.5 58.832 34.754 92.488 1.431 6.903-.154 15.7 5.455 20.95 9.874 6.041 22.2 3.74 33.221 4.575 17.033-.332 34.35 1.226 51.169-2.02 6.6-.891 10.125-7.224 9.963-13.415 1.187-20.914 6.496-41.436 15.483-60.355 8.665-20.184 21-38.602 29.271-58.938 1.099-2.148.797-5.893-2.578-4.761-7.976.847-16.238 3.774-23.932-.15-15.769-7.15-25.7-22.57-40.475-31.324-8.338-5.376-18.38-7.47-28.2-7.36m7.642 10.776c12.863 2.474 23.663 11.13 33.204 19.686 5.796 5.83 14.153 14.22 9.181 23.017-.823 3.234-4.823 3.139-5.45-.149-1.68-6.527-3.842-13.436-9.886-17.271-9.519-7.884-22.945-10.689-30.703-20.627-1.505-2.467 1.044-5.122 3.654-4.656"
              transform="matrix(.26458 0 0 .26458 339.68 80.326)"
            ></path>
            <path
              id="path51131"
              d="M239.934-99.895c-12.544 1.019-17.981 16.345-14.511 27.09 1.768 7.568 8.634 15.152 17.1 12.849 10.877-.022 23.947-9.252 19.413-21.39-2.386-9.956-11.514-18.459-22.002-18.55"
              transform="matrix(.26458 0 0 .26458 339.68 80.326)"
            ></path>
            <path
              id="path51170"
              d="M214.741 491.825c-24.363.187-50.525-1.328-72.504 11.061-12.285 6.8-15.862 22.401-14.087 35.366 2.716 10.532 15.426 11.537 24.313 13.188 39.157 5.245 78.797 5.484 118.05 9.513 2.231.82-4.392 1.061-4.322 1.042-42.47 3.398-85.294 3.817-127.4 10.799-14.767 3.094-30.368 7.231-41.914 17.422-7.687 8.564-8.4 23.844-.072 32.19 10.935 6.245 24.197 5.932 36.398 7.316 48.64 2.763 97.398 1.889 146.093 1.898 29.985-.521 60.144-.122 89.926-4.07 8.506-1.124 19.246-4.73 20.067-14.79 1.777-12.022-7.485-22.414-15.768-29.91-13.12-11.646-30.526-16.996-47.816-17.804-6.188-.8-12.858-.302-18.777-1.86 12.807-5.024 27.837-5.41 38.924-14.192 7.35-5.563 8.129-15.204 6.992-23.64-.887-14.022-13.795-23.427-26.52-26.427-33.756-8.575-68.945-6.603-103.46-7.106z"
              transform="matrix(.26458 0 0 .26458 339.68 80.326)"
            ></path>
            <path
              id="path51209"
              d="M215.132 642.338c-36.583.406-73.256-.089-109.748 2.834-7.94 1.458-18.162 1.242-23.308 8.491-2.069 6.845 6.789 9.763 11.903 10.83 28.186 5.147 57.041 4.044 85.57 4.47 60.233-.032 120.485.309 180.701-1.117 12.29-1.251 25.396-1.17 36.65-6.816 5.15-3.349 1.554-11.293-3.828-12.128-17.86-5.55-36.912-5.158-55.43-5.841-40.832-.699-81.673-.799-122.51-.723"
              transform="matrix(.26458 0 0 .26458 339.68 80.326)"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Queen;
