import React from "react";

interface RookProps {
  size?: number;
  className?: string;
}

const Rook: React.FC<RookProps> = ({ size = 24, className }) => {
  const width = (size / 263) * 167;
  const widthPx = `${width}px`;
  return (
    <div className="mx-auto w-full" style={{ width: widthPx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={size}
        fill="none"
        viewBox="0 0 165 253"
        className={
          className ||
          "drop-shadow-[0_0_5px_rgba(219,255,0,0.75)] drop-shadow-[0_0_10px_rgba(219,255,0,0.75)]"
        }
      >
        <g id="layer1" transform="translate(-350.115 -101.975)">
          <g
            id="g3447"
            fill="#facc15"
            fillOpacity="1"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            paintOrder="markers stroke fill"
          >
            <path
              id="path2593"
              strokeWidth="0.886"
              d="M66.476 224.023c-2.89 50.052-2.89 100.296-7.891 150.21-1.904 14.958-4.736 30.927-15.53 42.295-4.385 5.702-9.742 11.028-13.818 16.735 13.168 1.37 26.666.062 39.955.28 40.408-.774 80.869-.13 121.238-2.147 6.228-1.009 13.215-.97 18.826-3.756-4.207-13.745-15.715-24.226-19.074-38.441-5.88-24.022-4.757-49.03-5.72-73.57a16034 16034 0 0 1-1.259-95.707H66.91zm105.353 31.983c4.002 5.968 2.551 14.022 3.076 20.897-.626 8.395 1.1 17.7-2.946 25.369-2.52.94-1.611-4.017-1.915-5.421.015-13.262-.778-26.742.986-39.867.203-.245.293-1.098.8-.978m-1.057 90.565c4.77 4.24 3.668 11.92 4.076 17.794-.392 4.76.545 11.494-3.805 14.553-2.524-2.364-.748-7.167-1.44-10.366.183-7.236-.67-14.886.708-21.888a.76.76 0 0 1 .46-.093"
              transform="matrix(.26458 0 0 .26458 358.218 77.727)"
            ></path>
            <path
              id="path2632"
              strokeWidth="0.886"
              d="M99.846 444.269c-25.545.639-51.784-1.032-76.567 6.326-8.447 2.704-18.88 8.438-18.747 18.49 2.653 4.625 9.505 3.61 14.025 4.75 42.576 3.944 85.555 1.626 128.045 6.744 4.114.98 9.979.988 12.285 5.012-3.847 5.537-12.025 4.916-18.027 6.159-42.747 4.17-85.828 2.052-128.617 5.375-10.11 1.833-22.073 1.668-29.833 9.334-6.606 8.357-1.7 23.141 9.31 24.65 31.652 6.965 64.273 4.231 96.413 4.91 48.746-.178 97.513.13 146.242-1.2 10.56-1.626 22.683-1.438 31.213-8.716 5.828-7.55 1.828-19.067-6.247-23.334-13.892-8.734-31.218-6.123-46.638-9.599-5.105-.395-13.165-4.021-9.958-10.27 7.937-6.88 19.54-5.01 29.19-7.207 4.465-.857 12.923-1.712 11.33-8.307-3.463-9.744-12.414-18.008-23.035-18.89-28.394-5.253-57.441-3.37-86.159-4.13a4117 4117 0 0 0-34.225-.097"
              transform="matrix(.26458 0 0 .26458 358.218 77.727)"
            ></path>
            <path
              id="path2671"
              strokeWidth="0.886"
              d="M119.334 546.762c-40.848.322-81.738-.227-122.552 1.593-8.555 1.25-19.568.493-25.311 8.145-3.632 6.698-1.292 17.287 7.25 18.595 24.386 5.526 49.631 3.139 74.424 3.811 66.53-.105 133.071.102 199.59-1.147 8.767-1.052 20.869-.652 25.396-9.865 1.236-5.88.661-14.047-6.422-15.77-14.014-5.125-29.367-3.531-44.012-4.486-36.115-.846-72.24-.902-108.363-.876"
              transform="matrix(.26458 0 0 .26458 358.218 77.727)"
            ></path>
            <path
              id="path3244"
              strokeWidth="0.626"
              d="M43.632 91.962c-8.354.415-17.136-.786-25.153 2.034-5.952 3.932-5.116 12.21-5.48 18.47-.88 22.377 7.681 43.896 19.464 62.531 7.497 12.222 15.8 23.919 23.885 35.753q67.12-.007 134.24-.013c11.417-18.024 24.165-35.298 33.847-54.358 7.236-15.131 13.329-32.927 7.332-49.508-3.249-9.26-14.075-11.718-22.698-12.816-14.47-1.506-29.094-1.465-43.612-.921-6.127.8-10.022 6.15-11.581 11.738-2.154 8.007-7.647 15.133-15.519 18.137-12.269 4.649-27.51 3.54-37.804-5.07-7.173-7.02-8.292-19.469-18.502-23.359-9.768-3.633-20.479-2.152-30.684-2.62-2.578-.006-5.157 0-7.735.002m161.859 17.777c5.884-.618 12.639 2.294 13.782 8.65.813 4.103 2.013 10.51-2.732 12.621-4.902 1.235-6.755-4.926-7.787-8.513-2.677-5.508-9.954-4.64-15.002-6.01-2.145-.356-7.254-2.052-4.952-4.94 4.08-2.78 9.569-1.315 14.242-1.828.816-.007 1.633.012 2.449.02"
              transform="matrix(.26458 0 0 .26458 358.218 77.727)"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Rook;
