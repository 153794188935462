import React from "react";

interface KingProps {
  size?: number;
  className?: string;
}

const King: React.FC<KingProps> = ({ size = 24, className }) => {
  // Calculate width based on the aspect ratio (width:height = 80:172)
  const width = (size / 172) * 80;
  const widthPx = `${width}px`;

  return (
    <div style={{ width: widthPx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={size}
        fill="none"
        viewBox="0 0 150 350"
        className={
          className ||
          "drop-shadow-[0_0_5px_rgba(112,0,255,0.75)] drop-shadow-[0_0_10px_rgba(112,0,255,0.75)]"
        }
      >
        <g id="layer1" transform="translate(-358.218 -77.727)">
          <g
            id="g68254"
            fill="#903afe"
            fillOpacity="1"
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="4"
            strokeWidth="1.134"
            paintOrder="markers stroke fill"
          >
            <path
              id="path67974"
              d="M127.118-34.762c-9.71 2.022-7.774 14.864-11.871 21.717-2.502 7.74-12.314 4.826-16.366 11.092-5.577 7.68 3.664 16.595 11.581 16.576 9.831 3.441 6.17 16.277 4.944 24.035C114.09 53.36 102.71 64.235 89.78 69.816 74.872 78.041 57.398 82.94 45.15 95.192c-3.891 7.08 3.246 14.695 5.729 21.13 11.272 19.009 23.543 38.093 40.13 52.906 13.447 9.83 31.285 7.223 46.95 7.443 11.86-.222 24.437-2.946 32.115-12.843 16.492-18.216 32-38.224 41.309-61.143 3.145-9.211-8.686-13.7-14.586-17.914-17.026-10.227-38.463-14.923-51.308-30.986-5.165-10.171-7.763-22.358-6.38-33.722 3.252-8.54 17.94-4.442 19.15-14.972 1.634-7.928-6.887-11.872-13.286-12.864-9.885-5.844-4.257-23.596-16.522-26.948a7.4 7.4 0 0 0-1.333-.04m29.106 113.897c10.926.254 23.932 2.847 30.704 12.014-5.585 4.507-13.788-.235-20.034-1.362-8.471-2.633-16.752-5.93-24.484-10.302 4.604-.1 9.208-.369 13.814-.35m42.36 16.372c4.196.4 11.945 5.717 8.062 10.09-6.14 3.489-15.546-1.45-11.792-8.87.912-1.04 2.412-1.301 3.73-1.22"
              transform="matrix(.26458 0 0 .26458 363.772 87.078)"
            ></path>
            <path
              id="path68013"
              d="M124.775 186.939c-12.171.837-25.02-1.273-36.733 2.48-8.43 5.284-5.34 20.918 4.778 22.49 9.638 3.303 20.641 3.36 30.105 5.453-5.808 3.184-13.76 1.856-20.361 2.887-13.247 1.434-27.73.569-39.788 6.783-1.264 3.672 5.813 4.275 8.09 5.466 16.56 3.975 33.888 2.553 50.569 5.675-26.23 5.951-54.307 3.187-79.793 12.894-3.566.41-8.956 5.788-3.1 7.307 14.883 5.001 31.02 2.575 46.462 3.78 40.467.346 81.038 1.227 121.435-1.419 5.33-1.046 16.65-1.716 14.554-9.77-3.64-10.196-16.32-5.61-23.394-10.523-4.545-4.914 2.92-15.648-5.846-17.194-10.954-2.592-22.653-2.476-33.487-4.441.33-4.364 6.672-3.825 8.536-7.674 4.778-5.324 11.832-13.985 7.07-21.128-6.95-4.748-16.429-1.856-24.386-2.97-8.236-.127-16.474-.136-24.711-.096"
              transform="matrix(.26458 0 0 .26458 363.772 87.078)"
            ></path>
            <path
              id="path68052"
              d="M83.329 319.152c-.483 42.611.225 85.391-4.015 127.833-1.417 13.785-9.223 25.51-16.975 36.546 27.941 2.31 56.107.815 84.137 1.189 15.145-.293 30.613.258 45.568-.866-5.605-15.491-14.256-30.17-15.681-46.974-4.81-53.745-3.139-107.82-3.593-161.72-.426-6.477-12.351-1.449-17.665-3.036-23.918-.163-47.89.463-71.757-1.434z"
              transform="matrix(.26458 0 0 .26458 363.772 87.078)"
            ></path>
            <path
              id="path68091"
              d="M126.954 493.632c-23.886.63-47.994-.81-71.724 2.226-12.333 2.618-25.624 9.75-30.4 21.88 2.184 3.307 7.814 4.676 6.055 10.05 1.025 5.865-4.799 15.063 2.76 18.118 14.887 3.642 30.641 2.362 45.886 3.598 20.894 1.12 42.453.865 62.96 2.705-40.515 4.236-81.681 3.34-121.736 11.363-14.84 3.038-28.506 13.96-33.276 28.433.289 8.277 11.475 8.504 17.509 9.918 31.572 3.69 63.498 1.746 95.22 2.183 48.961-.764 98.047-.083 146.885-4.02 7.819-1.304 21.565-1.307 21.713-12.079-1.48-13.335-16.461-18.895-27.167-23.496-13.971-5.72-29.434-7.55-43.478-12.335 8.207-4.602 19.838-.735 27.57-6.723 3.272-7.354-5.168-16.93.691-23.521 7.492-2.085 5.206-5.96.727-10.813-9.646-13.028-26.25-16.752-41.6-16.818-19.515-.908-39.064-.647-58.595-.669"
              transform="matrix(.26458 0 0 .26458 363.772 87.078)"
            ></path>
            <path
              id="path68130"
              d="M148.14 616.122c-50.516.69-101.226-.795-151.557 4.297-6.982.552-19.725 3.365-16.497 13.111 6.558 6.949 17.846 5.03 26.537 6.31 81.412.818 162.845.779 244.254-.257 8.62-.843 20.708.67 26.068-7.65 3.491-7.682-3.594-15.417-11.46-14.813-39.094-1.305-78.233-.934-117.344-.998"
              transform="matrix(.26458 0 0 .26458 363.772 87.078)"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default King;
