import React from "react";

interface BishopProps {
  size?: number;
  className?: string;
}

const Bishop: React.FC<BishopProps> = ({ size = 24, className }) => {
  const width = (size / 263) * 167;
  const widthPx = `${width}px`;

  return (
    <div className="mx-auto w-full" style={{ width: widthPx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={size}
        viewBox="0 0 168 371"
        className={
          className ||
          "drop-shadow-[0_0_5px_rgba(0,255,44,0.75)] drop-shadow-[0_0_10px_rgba(0,255,44,0.75)]"
        }
      >
        <g
          id="layer1"
          fill="#44fe64"
          fillOpacity="1"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.208"
          paintOrder="markers stroke fill"
          transform="translate(-300.21 10.792)"
        >
          <path
            id="path38308"
            d="M-373.688 207.875c-.647 31.39 1.424 63.194-4.331 94.215-4.411 19.436-19.074 34.85-29.091 50.944 25.819 1.727 51.88-.001 77.786.033 24.625-1.227 49.792.927 73.96-4.693 2.627-2.268-2.757-6.508-3.378-9.118-9.267-14.238-20.7-28.067-22.54-45.652-7.55-46.592-5.756-94.047-9.396-140.961-3.301-4.409-13.214-.761-19.155-1.954h-63.855z"
            transform="matrix(.26458 0 0 .26458 428.484 43.975)"
          ></path>
          <path
            id="path38347"
            d="M-336.33 364.08c-26.145.71-52.573-.596-78.476 3.482-12.914 1.72-16.982 15.643-17.98 26.74-2.42 8.608 3.724 16.86 12.315 18.032 33.43 6.874 67.804 7.187 101.667 10.871 4.28.732 11.445.538 14.366 2.142-42.407 1.136-84.94 2.111-127.183 6.225-13.688 3.092-31.097 4.422-38.854 17.848-1.06 9.151 7.95 16.985 16.494 18.369 33.147 7.074 67.376 5.176 101.06 6.051 47.255-.23 94.67 1.071 141.78-3.245 9.134-.981 22.647-3.513 22.81-15.142 2.184-9.16-4.141-17.269-12.515-20.278-16.81-7.563-36.08-5.192-53.322-10.419 1.275-3.59 8.15-3.24 11.39-4.89 8.767-3.303 20.502-3.67 26.339-11.852.977-9.397-4.347-18.717-8.226-27.097-4.71-11.569-17.671-14.69-28.948-14.873-27.525-1.917-55.137-2.06-82.717-1.964"
            transform="matrix(.26458 0 0 .26458 428.484 43.975)"
          ></path>
          <path
            id="path38386"
            d="M-369.278 485.78c-33.226.639-66.665-1.182-99.727 2.706-5.817.92-16.704 3.618-14.4 11.483 6.648 8.45 19.27 7.34 28.946 8.998 44.045 2.142 88.201.853 132.29 1.101 41.32-.54 82.73.3 123.988-1.894 7.05-1.452 19.377-1.533 20.43-10.775-3.356-9.083-15.548-8.847-23.621-10.044-55.909-2.622-111.952-1.172-167.906-1.575"
            transform="matrix(.26458 0 0 .26458 428.484 43.975)"
          ></path>
          <path
            id="path38425"
            d="M-339.55 67.08c-14.834.518-33.629 1.55-41.098 16.855-4.106 9.036-14.353 9.874-22.333 13.379-5.01 3.31 3.524 6.937 6.229 7.98 23.179 5.91 47.448 5.683 71.122 8.487 5.724 1.252 13.55.039 17.791 4.465-7.39 3.775-16.979 2.27-25.226 3.355-27.372 1.804-55.082.693-82.189 5.243-2.73-.1-7.231 3.425-2.773 4.923 22.081 6.833 45.72 5.754 68.596 6.639 34.166-.164 68.635.38 102.465-4.95 4.79-1.63 12.508-2.097 14.36-7.351-5.262-3.67-13.085-1.892-19.289-3.21-8.066-1.047-16.994-.42-24.57-2.812 1.688-6.402 9.996-8.816 14.199-13.547 6.817-4.036-.975-10.939-4.37-14.466-19.027-18.92-46.897-25.091-72.913-24.99"
            transform="matrix(.26458 0 0 .26458 428.484 43.975)"
          ></path>
          <path
            id="path38464"
            d="M-329.353-205.868c-11.453 6.008-16.251 20.155-15.17 32.548.914 22.339-11.92 41.918-21.33 61.243-15.471 29.18-35.196 57.806-39.446 91.307-3.277 24.854 9.524 49.318 27.34 65.86 10.141 9.663 24.48 11.329 37.847 10.59 20.003-.712 41.651 2.16 60.008-7.466 16.34-11.95 24.646-32.212 28.72-51.487 2.603-15.674 3.526-32.724-4.873-46.924-14.412-32.52-36.877-60.734-50.622-93.547-4.911-9.225-2.938-19.493-3.444-29.388-1.77-12.55-7.972-25.733-18.756-32.755zm17.31 87.206c14.006 6.902 20.791 22.464 27.875 35.557 3.644 9.052 9.56 19.165 6.787 29.091-4.713.804-7.818-6.082-10.729-9.013-10.352-16.62-18.918-34.636-24.74-53.326-.07-.706-.388-2.41.807-2.309m45.201 110.566c8.307 3.032 2.654 13.139.712 18.72-5.088 9.935-9.054 23.075-20.246 27.531-6.042-.147-2.692-8.8-2.329-12.49 3.85-12.439 9.032-26.592 20.663-33.647a2.9 2.9 0 0 1 1.2-.114"
            transform="matrix(.26458 0 0 .26458 428.484 43.975)"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Bishop;
